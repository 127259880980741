import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { NouisliderModule } from 'ng2-nouislider';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TagInputModule } from 'ngx-chips';
import { PipeModule } from '../@core/pipes/pipe.module';
import { MaterialDesignModule } from '../material.module';
import { EDITOR_COMPONENTS } from './components.list';
import { GalleryDialogComponent } from './video/gallery-dialog/gallery-dialog.component';
import { SharedComponentsModule } from './shared/shared.module';
import { TranslateSharedModule } from '../translate/translate.module';
import { AsapUploadModule } from 'asap-upload';
import { EmailEditorModule } from 'angular-email-editor';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { InnerColor } from './page/inner-color.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipeModule,
    ColorPickerModule,
    NouisliderModule,
    TagInputModule,
    EditorModule,
    MaterialDesignModule,
    SharedComponentsModule,
    TranslateSharedModule,
    AsapUploadModule,
    EmailEditorModule,
    CarouselModule
  ],
  declarations: [
    ... EDITOR_COMPONENTS,
    InnerColor
  ],
  exports: [
    ... EDITOR_COMPONENTS,
    AsapUploadModule,
    CarouselModule
  ],
  entryComponents: [
    GalleryDialogComponent
  ]
})

export class ComponentsModule { }
