import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AsapService } from 'asap-crud';

declare var $: any;

@Component({
  selector: 'app-editor-component-page-config',
  templateUrl: 'page-config.component.html',
  providers: [ AsapService ]
})
export class EditorPageConfigComponent implements OnInit, OnChanges {

  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  public sections;
  public pages;
  
  pageLoading: boolean;

  constructor(
    public service: AsapService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item && this.item) {
      this.loadPages(this.item.section_id);
    }
  }

  ngOnInit() {
    this.loadSections();
  }

  setAlign(attr: string, align: string) {
    this.item[attr] = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  loadSections() {
    this.service.basePath = "api/v2/admin";
    this.service.entityName = "static-page-section";
    this.service.getResources().subscribe((response) => {
      this.sections = response.data;
    });
  }

  loadPages(section?: number) {
    this.service.basePath = "api/v2/admin";
    if (section) {
      this.service.entityName = `static-page?search=${btoa(`section_id=${section}`)}`;
    }
    else {
      this.service.entityName = `static-page`;
    }
    this.service.getResources().subscribe((response) => {
      this.pages = response.data;
      this.selectPage(this.item.page_id);
    });
  }

  selectSection(id) {
    this.pages = null;
    this.loadPages(id);
  }

  selectPage(id) {
    const page = this.pages.find(p => p.id == id);
    if (page) {
      this.item.content = {
        html: page.text
      };
    }
    else {
      this.item.content = null;
    }
    this.item.loaded = true;
  }


}
