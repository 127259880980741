import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EmailEditorComponent } from 'angular-email-editor';
import { InnerColor } from './inner-color.pipe';

@Component({
  selector: 'app-editor-component-page',
  templateUrl: 'page.component.html',
  styleUrls: [ './page.component.scss' ],
  providers: [InnerColor],
})
export class EditorPageComponent implements OnInit, OnChanges {

  @Input() item: any;
  public base64: any = null; 
  public isLoaded: boolean = false; 

  @ViewChild(EmailEditorComponent, {static: false}) editor: EmailEditorComponent;

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.item && this.item.content) {
      this.generateBase64(); 
      this.isLoaded = true;  
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['item'] && this.item && this.item.content) {
      this.generateBase64();  
    }
  }

  private generateBase64() {
    const content = btoa(unescape(encodeURIComponent(this.item.content.html)));
    this.base64 = this.sanitizer.bypassSecurityTrustResourceUrl(`data:text/html;base64,${content}`);
  }
}
